import { computed, ref } from 'vue';
import { showSuccess } from '@/store/toastMessage';

export const state = {
    installEvent: ref<any>(null),
    updateEvent: ref<any>(null),
};

export const actions = {
    installApp: async () => {
        const deferredPrompt = state.installEvent.value;
        deferredPrompt.prompt();
        const { outcome } = await deferredPrompt.userChoice;
        if (outcome === 'accepted') {
            state.installEvent.value = null;
        }
    },
    updateApp: () => {
        const registration = state.updateEvent.value.detail;
        if (!registration || !registration.waiting) return;
        registration.waiting.postMessage({ type: 'SKIP_WAITING' });
        state.updateEvent.value = null;
        showSuccess(
            'Update successfully installed! Please close and restart the app',
        );
    },
};

export const getters = {
    canBeInstalled: computed(() => {
        return !!state.installEvent.value;
    }),
    canBeUpdated: computed(() => {
        return !!state.updateEvent.value;
    }),
};
