import { computed } from 'vue';

import { getTenants } from '@/store/tenant';
import { getListCustomers } from '@/store/customers';
import { getListCustomerSites } from '@/store/customerSites';

export const getBaseAppData = () => {
    const { loading: loadingTenants } = getTenants();
    const { loading: loadingCustomers } = getListCustomers();
    const { loading: loadingSites } = getListCustomerSites();
    const loading = computed(
        () =>
            loadingTenants.value ||
            loadingSites.value ||
            loadingCustomers.value,
    );
    return {
        loading,
    };
};
