import AWSAppSyncClient from 'aws-appsync';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import Auth from './graph-ql.auth';

const appSyncConfig = {
    url: 'https://zbhkmp52uvaqpod5wej3rniebm.appsync-api.eu-west-1.amazonaws.com/graphql',
    region: 'eu-west-1',
    auth: {
        type: 'AMAZON_COGNITO_USER_POOLS',
        jwtToken: async () => {
            try {
                return (await Auth.currentSession()).getIdToken().getJwtToken();
            } catch (e) {
                console.error(e);
                return '';
            }
        },
    },
};
const options = {
    defaultOptions: {
        watchQuery: {
            fetchPolicy: 'cache-and-network',
        },
    },
};

// @ts-expect-error comment
const awsAppSyncClient = new AWSAppSyncClient(appSyncConfig, options);
// @ts-expect-error comment
export const defaultClient = new ApolloClient({
    ...awsAppSyncClient,
    cache: new InMemoryCache(),
});
