/* eslint-disable */
import Auth from '@aws-amplify/auth';
import Amplify from 'aws-amplify';
import awsConfigs from './graph-ql.exports';
import { Capacitor } from '@capacitor/core';

const amplifyConfig = {
    aws_appsync_graphqlEndpoint: awsConfigs.graphqlEndpoint,
    aws_appsync_region: awsConfigs.region,
    aws_appsync_authenticationType: awsConfigs.authenticationType,
    federationTarget: 'COGNITO_USER_POOLS',
    authenticationFlowType: 'USER_SRP_AUTH',

    Auth: {
        region: 'eu-west-1',
        userPoolId: 'eu-west-1_IHDT21pHG',
        userPoolWebClientId: '2554rkrsdpqe8nbfl6dt97feeu',

        identityPoolId: 'eu-west-1:0d4607f2-5789-4983-9e3a-4c5acc498f0b',
        mandatorySignIn: true,
        oauth: {
            domain: 'appsignin.nurtio.com',
            scope: [
                'email',
                'profile',
                'openid',
                'aws.cognito.signin.user.admin',
            ],
            responseType: 'code',
        },
    },
};

// Determine local vs production redirect
// From: https://docs.amplify.aws/lib/auth/social/q/platform/js/#configure-auth-category
const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
        // [::1] is the IPv6 localhost address.
        window.location.hostname === '[::1]' ||
        // 127.0.0.1/8 is considered localhost for IPv4.
        window.location.hostname.match(
            /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/,
        ),
);

const redirectUrl = Capacitor.isNativePlatform()
    ? 'nurtio://signedIn'
    : isLocalhost
    ? 'http://localhost:8080/'
    : `https://${window.location.hostname}/`;

const updatedAmplifyConfig = {
    ...amplifyConfig,
    Auth: {
        ...amplifyConfig.Auth,
        oauth: {
            ...amplifyConfig.Auth.oauth,
            redirectSignIn: redirectUrl,
            redirectSignOut: redirectUrl,
        },
    },
};

Amplify.configure(updatedAmplifyConfig);
Auth.configure(updatedAmplifyConfig);
Amplify.configure(updatedAmplifyConfig);

export default Auth;
