import { useListServiceSitesQuery, ServiceSite } from '@/generated/graphql';
import { computed, ref, watch } from 'vue';
import { tenantId } from './tenant';

// STATE
const state = {
    customerSites: ref<Readonly<ServiceSite[]>>([]),
    refetchCustomerSites: ref<any>(null),
    loading: ref(false),
};

// ACTIONS
export const getListCustomerSites = () => {
    const { result, refetch, ...other } = useListServiceSitesQuery({
        limit: 1000,
        tenantId: tenantId?.value || null,
    });
    state.refetchCustomerSites.value = refetch;
    state.customerSites = computed(
        () =>
            result.value?.listServiceSites?.items?.filter(
                (c): c is ServiceSite => c !== null,
            ) || [],
    );
    watch(loading, (newLoading) => {
        state.loading.value = newLoading;
    });
    watch(tenantId, (newValue, oldValue) => {
        if (oldValue != newValue) {
            refetch();
        }
    });

    return { refetch, ...other };
};

// GETTERS
export const loading = computed(() => state.loading.value);

export const listCustomerSites = computed(() => state.customerSites.value);

export const refetchCustomerSitesAsync = async () => {
    if (state.refetchCustomerSites.value) {
        await state.refetchCustomerSites.value();
    }
};
