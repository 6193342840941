import { createApp, h, provide } from 'vue';
import App from './App.vue';
import router from './router';
import './registerServiceWorker';
import { App as CapacitorApp, URLOpenListenerEvent } from '@capacitor/app';
import OAuth from '@aws-amplify/auth';

import {
    IonicVue,
    IonButton,
    IonCard,
    IonChip,
    IonContent,
    IonFab,
    IonFabButton,
    IonHeader,
    IonIcon,
    IonImg,
    IonItem,
    IonLabel,
    IonPage,
    IonText,
} from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';

/* plugins */
import { DefaultApolloClient } from '@vue/apollo-composable';
import { defaultClient } from './configs/graph-ql';
import VueApexCharts from 'vue3-apexcharts';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import VueGtag from 'vue-gtag';
import { selectedTenant } from '@/store/tenant';
import { RouteLocationNormalized } from 'vue-router';

const getConfig = () => {
    const config = {
        swipeBackEnabled: false,
        animated: true,
    };
    return config;
};

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

const app = createApp({
    setup() {
        provide(DefaultApolloClient, defaultClient);
    },
    render() {
        return h(App);
    },
})
    .use(pinia)
    .use(IonicVue, getConfig())
    .use(router)
    .use(VueApexCharts)
    .use(
        VueGtag,
        {
            config: {
                id: 'G-HV74D4PR1T',
                params: {
                    anonymize_ip: true,
                },
            },
            pageTrackerScreenviewEnabled: true,
            pageTrackerTemplate(to: RouteLocationNormalized) {
                return {
                    organization: selectedTenant.value?.name || '???',
                    page_path: to.path,
                    page_location: window.location.href,
                };
            },
        },
        router,
    );

app.component('ion-button', IonButton);
app.component('ion-card', IonCard);
app.component('ion-chip', IonChip);
app.component('ion-content', IonContent);
app.component('ion-fab', IonFab);
app.component('ion-fab-button', IonFabButton);
app.component('ion-header', IonHeader);
app.component('ion-icon', IonIcon);
app.component('ion-img', IonImg);
app.component('ion-item', IonItem);
app.component('ion-label', IonLabel);
app.component('ion-page', IonPage);
app.component('ion-text', IonText);

router.isReady().then(() => {
    app.mount('#app');
});

Sentry.init({
    app,
    dsn: 'https://b6f8737eb67f4b0c85d885ea01ee7e49@o1072828.ingest.sentry.io/6072187',
    integrations: [
        new Integrations.BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            tracingOrigins: ['dev-app.nurtio.com', 'app.nurtio.com', /^\//],
        }),
    ],
    tracesSampleRate: process.env.NODE_ENV === 'development' ? 1.0 : 0.2,
    beforeSend: (event, hint) => {
        if (process.env.NODE_ENV == 'development') {
            console.error(hint?.originalException || hint?.syntheticException);
            return null;
        }
        return event;
    },
});

CapacitorApp.addListener(
    'appUrlOpen',
    async function (event: URLOpenListenerEvent) {
        //window.location.href = `?${new URL(event.url).search}`;

        // const router = useRouter();
        // await router.replace({ path: '/', query: params });

        await (OAuth as any).handleAuthResponse(
            `?${new URL(event.url).search}`,
        );
        // alert('done');
        // location.reload();
        alert('done2');
    },
);
