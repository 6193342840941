import { useListCustomersQuery, Customer } from '@/generated/graphql';
import { watch, computed, ref } from 'vue';
import { tenantId } from './tenant';

// STATE
const state = {
    customers: ref<Readonly<Customer[]>>([]),
    refetch: ref<any>(null),
    loading: ref(false),
};

// ACTIONS
export const getListCustomers = () => {
    const { result, loading, error, refetch } = useListCustomersQuery({
        limit: 1000,
        tenantId: tenantId?.value || null,
    });
    state.customers = computed(
        () =>
            result.value?.listCustomers?.items?.filter(
                (c): c is Customer => c !== null,
            ) || [],
    );
    watch(loading, (newLoading) => {
        state.loading.value = newLoading;
    });
    watch(tenantId, (newValue, oldValue) => {
        if (oldValue != newValue) {
            refetch();
        }
    });
    state.refetch.value = refetch;
    return {
        loading,
        error,
        refetch,
    };
};

export const refetchCustomersAsync = async () => {
    if (state.refetch.value) {
        await state.refetch.value();
    }
};

// GETTERS
export const loading = computed(() => state.loading.value);

export const listCustomers = computed(() => state.customers.value);
