import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_router_outlet = _resolveComponent("ion-router-outlet")!
  const _component_ion_toast = _resolveComponent("ion-toast")!
  const _component_ion_app = _resolveComponent("ion-app")!

  return (_openBlock(), _createBlock(_component_ion_app, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_router_outlet, { animated: false }),
      _createVNode(_component_ion_toast, {
        "is-open": _ctx.toast.show,
        message: _ctx.toast.message,
        color: _ctx.toast.color
      }, null, 8, ["is-open", "message", "color"]),
      _createVNode(_component_ion_toast, {
        "is-open": !_ctx.toast.show && _ctx.showUpdateToast,
        message: "An update is available",
        color: "primary",
        buttons: [
                {
                    side: 'start',
                    icon: _ctx.closeOutline,
                    handler: () => (_ctx.showUpdateToast = false),
                },
                {
                    side: 'end',
                    text: 'UPDATE',
                    handler: _ctx.updateApp,
                },
            ]
      }, null, 8, ["is-open", "buttons"])
    ]),
    _: 1
  }))
}