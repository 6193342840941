import { computed, reactive } from 'vue';
import { Hub } from 'aws-amplify';

import Router from '@/router';
import Auth from '../configs/graph-ql.auth';

import { showError } from './toastMessage';
import { refetchTenantsAsync } from '@/store/tenant';
import { refetchCustomersAsync } from '@/store/customers';
import { refetchCustomerSitesAsync } from '@/store/customerSites';
import { refetchPlantTypesAsync } from '@/store/plantTypes';

const user = reactive({ flags: [''], gotFlags: false });

Hub.listen('auth', async (data) => {
    switch (data.payload.event) {
        case 'signIn': {
            user.gotFlags = false;
            user.flags = [];
            getFlags();
            await refetchUserRelatedDataAsync();
            Router.push({ path: '/' });
            break;
        }
        case 'signOut': {
            user.gotFlags = false;
            user.flags = [];
            Router.push({ path: '/signin' });
            break;
        }
        case 'signIn_failure': {
            console.log('user sign in failed');
            break;
        }
    }
});

const getFlags = () => {
    Auth.currentSession().then((auth) => {
        user.flags = auth
            .getAccessToken()
            .payload['cognito:groups'].filter((g: string) =>
                g.startsWith('flag:'),
            )
            .map((g: string) => g.replace('flag:', ''));
        user.gotFlags = true;
    });
};

export const refreshUserAsync = async () => {
    await Auth.currentAuthenticatedUser({ bypassCache: true });
};

export const refetchUserRelatedDataAsync = async () => {
    await refetchTenantsAsync();
    await Promise.all([
        refetchCustomersAsync(),
        refetchCustomerSitesAsync(),
        refetchPlantTypesAsync(),
    ]);
};

export const isUserLoggedInAsync = async () => {
    try {
        return !!(await Auth.currentAuthenticatedUser());
    } catch {
        return false;
    }
};

export const signOutAsync = async () => {
    try {
        await Auth.signOut();
    } catch (error) {
        showError('Logout error, please retry');
    }
};

export const isFlagEnabled = computed(() => (name: string) => {
    return user.flags.includes(name);
});

export const isFlagEnabledEc = computed(() => {
    return isFlagEnabled.value('ec');
});

if (!user.gotFlags) {
    getFlags();
}
