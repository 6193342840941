import { useListTenantsQuery } from '@/generated/graphql';
import { computed, ref } from 'vue';
import { Tenant } from './tenant.types';

import naturalCompare from 'natural-compare-lite';

// ======
// STATE
// ======
const state = {
    tenants: ref<Readonly<Tenant[]>>([]),
    refetch: ref<any>(null),
};

// ACTIONS
export const getTenants = () => {
    const { result, refetch, ...other } = useListTenantsQuery();

    state.tenants = computed(
        () =>
            result.value?.listTenants?.items?.filter(
                (c): c is Tenant => c !== null,
            ) || [],
    );

    state.refetch.value = refetch;
    return { refetch, ...other };
};

export const refetchTenantsAsync = async () => {
    if (state.refetch.value) {
        await state.refetch.value();
    }
};

// GETTERS
export const tenantsList = computed(() => {
    if (!state.tenants) return [];
    return state.tenants?.value && state.tenants.value.length
        ? state.tenants.value
              ?.slice()
              .sort((a, b) =>
                  naturalCompare(
                      (a.name || '').toLowerCase(),
                      (b.name || '').toLowerCase(),
                  ),
              )
        : state.tenants.value;
});

export const defaultTenant = computed(() => {
    if (!state.tenants) return { name: '???' } as Tenant;
    return state.tenants?.value && state.tenants.value.length
        ? state.tenants.value[0]
        : ({ name: '???' } as Tenant);
});

// Mobile app currentlu does not allow tenant selection: so it is always default
export const selectedTenant = computed(() => defaultTenant.value);

// Mobile app currentlu does not allow tenant selection: so it is always default
export const tenantId = computed(() => selectedTenant.value.id);

export const culture = computed(() => {
    return selectedTenant.value?.culture || 'en-US';
});
