import { useListPlantTypesQuery, PlantType } from '@/generated/graphql';
import { computed, ref, watch } from 'vue';
import { PlantTypesById } from './plantTypes.types';

// STATE
const state = {
    plantTypes: ref<Readonly<PlantType[]>>([]),
    loading: ref(false),
    refetch: ref<any>(null),
};

// ACTIONS
export const getListPlantTypes = () => {
    const { result, loading, error, refetch } = useListPlantTypesQuery({
        limit: 1000,
    });
    state.plantTypes = computed(
        () =>
            result.value?.listPlantTypes?.items?.filter(
                (c): c is PlantType => c !== null,
            ) || [],
    );

    watch(loading, (newLoading) => {
        state.loading.value = newLoading;
    });
    state.refetch.value = refetch;
    return {
        loading,
        error,
        refetch,
    };
};

export const refetchPlantTypesAsync = async () => {
    if (state.refetch.value) {
        await state.refetch.value();
    }
};

// GETTERS
export const loading = computed(() => state.loading.value);

export const listPlantTypes = computed((): PlantTypesById => {
    return state.plantTypes.value.reduce(
        (resultList: PlantTypesById, item: PlantType) => {
            return (
                (resultList[item.id] = {
                    id: item.id,
                    name: item.fullName,
                    text: item.fullName,
                }),
                resultList
            );
        },
        {},
    );
});
