
import { IonApp, IonRouterOutlet, IonToast } from '@ionic/vue';
import { defineComponent, computed } from 'vue';

import { toast } from '@/store/toastMessage';

import { getBaseAppData } from './App.composition';
import { state, actions } from '@/store/appInstallUpdate';
import { closeOutline } from 'ionicons/icons';

export default defineComponent({
    name: 'App',
    components: {
        IonApp,
        IonRouterOutlet,
        IonToast,
    },
    setup() {
        getBaseAppData();
        // APP INSTALL/UPDATE
        const listenersOptions = { once: true };
        // update
        const showUpdateToast = computed(() => {
            return !!state.updateEvent.value;
        });
        const setUpdateAvailable = (event: any) => {
            state.updateEvent.value = event;
        };
        document.addEventListener(
            'updateAvailable',
            setUpdateAvailable,
            listenersOptions,
        );
        const { updateApp } = actions;

        // install
        const setInstallAvailable = (event: any) => {
            state.installEvent.value = event;
        };
        window.addEventListener(
            'beforeinstallprompt',
            setInstallAvailable,
            listenersOptions,
        );
        return {
            toast,
            updateApp,
            showUpdateToast,
            closeOutline,
        };
    },
});
