import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import { isUserLoggedInAsync } from '@/store/user';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        redirect: '/tabs/tabPlants',
    },
    {
        path: '/app',
        redirect: '/tabs/tabPlants',
    },
    {
        path: '/tabs/',
        component: () => import('@/views/TabsPage.vue'),
        children: [
            {
                path: '',
                redirect: '/tabs/tabPlants',
            },
            {
                name: 'home',
                path: 'tabPlants',
                component: () =>
                    import('@/views/PageSitesAndAlerts/PageSitesAndAlerts.vue'),
            },
            {
                name: 'site',
                path: 'tabPlants/site/:siteId',
                component: () =>
                    import(
                        '@/views/PagePlantsAndAlerts/PagePlantsAndAlerts.vue'
                    ),
                props: true,
            },
            {
                name: 'plant',
                path: 'tabPlants/plant/:plantId/:isSensorRedirect?',
                component: () => import('@/views/PagePlant/PagePlant.vue'),
                props: true,
            },
            {
                name: 'qr',
                path: 'tabQr',
                component: () => import('@/views/PageScanQr.vue'),
            },
            {
                name: 'nfc',
                path: 'tabNfc',
                component: () => import('@/views/PageScanNfc.vue'),
            },
            {
                name: 'visit',
                path: 'tabVisit',
                component: () => import('@/views/PageVisit.vue'),
            },
            {
                name: 'visitOnSite',
                path: 'tabVisit/ongoing',
                component: () => import('@/views/PageVisitOnSite.vue'),
            },
        ],
    },
    {
        name: 'signin',
        path: '/signin',
        component: () => import('@/views/PageLogin.vue'),
        props: true,
    },
    {
        name: 'invite',
        path: '/invite',
        component: () => import('@/views/PageInvite.vue'),
    },
    {
        name: 'scan',
        path: '/:linkType?/:sensorId',
        component: () => import('@/views/PageItemScanned/PageItemScanned.vue'),
        props: true,
    },
    {
        name: 'demo',
        path: '/demo',
        component: () => import('@/views/Demo/DemoPagePlant.vue'),
        props: true,
    },
    {
        path: '/:catchAll(.*)',
        name: 'NotFound',
        component: () => import('@/views/PageNotFound.vue'),
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach(async (to) => {
    if (
        to.params.linkType == 'qr' &&
        (to.params.sensorId == '4240060' || to.params.sensorId == '4240061')
    ) {
        return '/demo';
    }

    if (to.path !== '/demo') {
        const isUserLoggedIn = await isUserLoggedInAsync();
        if (isUserLoggedIn) {
            if (to.path === '/signin') {
                return '/';
            }
        } else {
            if (to.path !== '/invite' && to.path !== '/signin') {
                return '/signin';
            }
        }
    }
});

export default router;
