import { reactive } from 'vue';

export const toast = reactive({
    show: false,
    color: '',
    message: '',
    timeout: 3000,
});

const showToast = (message: string, color = '') => {
    if (toast.show) {
        toast.show = false;
    }
    toast.show = true;
    toast.message = message;
    if (color) toast.color = color;
    setTimeout(() => {
        toast.show = false;
        toast.color = '';
        toast.message = '';
    }, toast.timeout);
};

export const showError = (message: string) => {
    const color = 'danger';
    showToast(message, color);
};

export const showSuccess = (message: string) => {
    const color = 'primary';
    showToast(message, color);
};
